import { useState } from "react";
import "./App.css";

import Header from "./components/Header";
import { UserInfo } from "./contexts/AuthContext";
import AuthContextProvider from "./contexts/AuthContextProvider";
import Home from "./components/Home";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from "notistack";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import dayjs from 'dayjs';
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const [user, setUser] = useState<UserInfo>();

  //test
  return (
    <>
      <AuthContextProvider value={{ user, setUser }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider>
            <Header />
            <Home />
          </SnackbarProvider>
        </LocalizationProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
