import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import format from "date-fns/format";
import React from "react";
import LocationOn from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";

const JobTimeline = (props: any) => {
  const { row } = props;

  const clipboard = (loc: string) => {
    navigator.clipboard.writeText(loc);
  };

  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent>
          {dayjs(row.pickupTime).format("DD-MMM HH:mm")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <LocationOn />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div onClick={() => clipboard(row.pickupFrom)}>{row.pickupFrom}</div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {dayjs(row.dropTime).format("DD-MMM HH:mm")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <LocationOn />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <div onClick={() => clipboard(row.pickupFrom)}>{row.dropTo}</div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default JobTimeline;
