import { useEffect, useState } from "react";
import useAuthContext from "./useAuthContext";

const useDeleteApi = (props: any) => {
  const { url } = props
  const { getToken } = useAuthContext();
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false);

  const token = getToken();

  const onDelete = async (id: number) => {
    setIsLoading(true);

    const apiUrl = `${process.env.REACT_APP_API}${url}/${id}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      setIsLoading(false);
    }
    catch (ex) {
      throw ex
    }
  }

  return { data, isLoading, onDelete };
};

export default useDeleteApi;
