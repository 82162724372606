import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid2, InputAdornment, Paper, TextField } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import GMRouteMap from "../../shared/GMRouteMap"
import useGetApi from "../../hooks/useGetApi"
import GMAddress from "../../shared/GMAddress"

const JobDriver = (props: any) => {
    const { formik } = props

    const { data: vehicles } = useGetApi({ url: `/activities/GetRefList` });
    const { data: drivers } = useGetApi({ url: `/drivers/GetRefList` });

    return <>

        <Paper style={{ padding: 10, marginTop: 10 }}>

            <Grid2 container spacing={2} >
                <Grid2 size={3}>
                    <DateTimePicker
                        name="pickupTime"
                        label="Pickup Time"
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                        value={dayjs(formik.values.pickupTime)}
                        onChange={(newValue: any) => { formik.setFieldValue('pickupTime', newValue.format('YYYY-MM-DDTHH:mm:ss')); }}
                        slotProps={{ textField: { fullWidth: true } }}
                    />
                </Grid2>
                <Grid2 size={9}>
                    <GMAddress label="Pickup From" name='pickupFrom' formik={formik}></GMAddress>
                </Grid2>
                <Grid2 size={3}>
                    <DateTimePicker
                        name="dropTime"
                        label="Drop Time"
                        format="DD/MM/YYYY HH:mm"

                        ampm={false}
                        value={dayjs(formik.values.dropTime)}
                        onChange={(newValue: any) => { formik.setFieldValue('dropTime', newValue.format('YYYY-MM-DDTHH:mm:ss')); }}
                        slotProps={{ textField: { fullWidth: true } }}
                    />
                </Grid2>
                <Grid2 size={9}>
                    <GMAddress label="Drop To" name='dropTo' formik={formik}></GMAddress>
                </Grid2>
                <Grid2 size={12}>
                    <GMRouteMap
                        center={{
                            latitude: -31.958460,
                            longitude: 115.951720
                        }}
                        route={{
                            fromAddress: formik.values.pickupFrom,
                            toAddress: formik.values.dropTo
                        }}
                        showResult
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Autocomplete
                        fullWidth
                        id="vehicle"
                        options={vehicles ?? []}
                        getOptionLabel={(option: any) => option?.name ?? ''}
                        isOptionEqualToValue={(option, value) => option?.activityID === value?.activityID}
                        value={formik.values.activity ?? ''}
                        onChange={(_, newValue) => {
                            formik.setFieldValue('activity', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} name="activity" label="Vehicle" />}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Autocomplete
                        fullWidth
                        id="driver"
                        options={drivers ?? []}
                        getOptionLabel={(option: any) => option?.name ?? ''}
                        isOptionEqualToValue={(option, value) => option?.driverID === value?.driverID}
                        value={formik.values.driver ?? ''}
                        onChange={(_, newValue) => {
                            formik.setFieldValue('driver', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} name="driver" label="Driver" />}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="driverWages"
                        name="driverWages"
                        label="Driver Wages"
                        type="number"
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                        value={formik.values.driverWages ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="totalHours"
                        name="totalHours"
                        label="Hours"
                        type="number"
                        value={formik.values.totalHours ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid2>
            </Grid2>

        </Paper>

    </>
}

export default JobDriver