import { AuthContext } from "./AuthContext";

const AuthContextProvider = (props: any) => {
  return (
    <AuthContext.Provider value={props.value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
