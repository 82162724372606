import { Grid2 } from "@mui/material";
import React from "react";

const NoMatch = () => {
  return (
    <>
      <Grid2
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid2 size={6}>
          <div>Page Not Found</div>
        </Grid2>
      </Grid2>
    </>
  );
};

export default NoMatch;
