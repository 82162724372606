import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid2, InputAdornment, Paper, TextField } from "@mui/material"
import useGetApi from "../../hooks/useGetApi"

const JobPayment = (props: any) => {
    const { formik } = props

    const { data: paymentMethods } = useGetApi({ url: `/paymentMethods/GetRefList` });
    const { data: creditCards } = useGetApi({ url: `/creditcards/GetRefList` });

    return <>

        <Paper style={{ padding: 10, marginTop: 10 }}>

            <Grid2 container spacing={2} >

                <Grid2 size={3}>
                    <Autocomplete
                        fullWidth
                        id="paymentMethod"
                        options={paymentMethods ?? []}
                        getOptionLabel={(option: any) => option?.name ?? ''}
                        isOptionEqualToValue={(option, value) => option?.paymentMethodID === value?.paymentMethodID}
                        value={formik.values.paymentMethod ?? ''}
                        onChange={(_, newValue) => {
                            formik.setFieldValue('paymentMethod', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} name="paymentMethod" label="Payment Method" />}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Autocomplete
                        fullWidth
                        id="creditCard"
                        options={creditCards ?? []}
                        getOptionLabel={(option: any) => option?.name ?? ''}
                        isOptionEqualToValue={(option, value) => option?.creditCardID === value?.creditCardID}
                        value={formik.values.creditCard ?? ''}
                        onChange={(_, newValue) => {
                            formik.setFieldValue('creditCard', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} name="creditCard" label="Credit Card" />}
                    />
                </Grid2>
                <Grid2 size={6}></Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="baseFee"
                        name="baseFee"
                        label="Base Fee"
                        type="number"
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                        value={formik.values.baseFee ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="airportFee"
                        name="airportFee"
                        label="Airport Fee"
                        type="number"
                        value={formik.values.airportFee ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="cleanFee"
                        name="cleanFee"
                        label="Clean Fee"
                        type="number"
                        value={formik.values.cleanFee ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="childRestraint"
                        name="childRestraint"
                        label="Child Restraint"
                        type="number"
                        value={formik.values.childRestraint ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="luggageTrailer"
                        name="luggageTrailer"
                        label="Luggage Trailer"
                        type="number"
                        value={formik.values.luggageTrailer ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="creditCardFee"
                        name="creditCardFee"
                        label="Credit Card Fee"
                        type="number"
                        value={formik.values.creditCardFee ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="amountBeforeGst"
                        name="amountBeforeGst"
                        label="Amount Ex Gst"
                        type="number"
                        value={formik.values.amountBeforeGst ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                        helperText="=BaseFee + Airport Fee + Clean Fee + Child Restraint + Luggage Trailer + Credit Card Fee"
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="taxiReformLevy"
                        name="taxiReformLevy"
                        label="Taxi Reform Levy"
                        type="number"
                        value={formik.values.taxiReformLevy ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                        helperText="=10% Base Fee (Max 10$) from 01/04/2019)"
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="totalExGst"
                        name="totalExGst"
                        label="Total Ex Gst"
                        type="number"
                        value={formik.values.totalExGst ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                        helperText="=Amount ex GST + Taxi Levy"
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="gst"
                        name="gst"
                        label="GST"
                        type="number"
                        value={formik.values.gst ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                disabled: true
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="totalAmount"
                        name="totalAmount"
                        label="Total"
                        type="number"
                        value={formik.values.totalAmount ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText="=Total ex GST + GST"
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isSentToDriver"
                                    checked={formik.values.isSentToDriver ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Sent to Driver" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isFinished"
                                    checked={formik.values.isFinished ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Finished" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isInvoiced"
                                    checked={formik.values.isInvoiced ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Invoiced" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isMYOBUpdated"
                                    checked={formik.values.isMYOBUpdated ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Updated to MYOB" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isCancelled"
                                    checked={formik.values.isCancelled ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Cancelled" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isPaid"
                                    checked={formik.values.isPaid ?? ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Paid" />
                    </FormGroup>
                </Grid2>
            </Grid2>

        </Paper>
    </>
}

export default JobPayment