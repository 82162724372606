import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const ConfirmationDialog = (props: any) => {
    const { open,
        title = "Confirmation",
        text = "Are you sure?",
        onYes,
        onNo
    } = props

    return <Dialog
        open={open}
        keepMounted
        onClose={onNo}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText >
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onYes}>Yes</Button>
            <Button onClick={onNo}>No</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmationDialog