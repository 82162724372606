import { Button, Grid2, TextField } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import useAuthContext from "../hooks/useAuthContext";

const Login = () => {
  const { handleSubmit, control } = useForm();
  const { login } = useAuthContext();

  const authenticate = async (data: any) => {
    await login(data.email, data.password);
  };

  return (
    <>
      <form onSubmit={handleSubmit(authenticate)}>
        <Grid2
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "50vh" }}
        >
          <Grid2 size={4}>
            <Controller
              name="email"
              control={control}
              defaultValue="admin@atlascorpcars.com.au"
              render={({ field }) => (
                <TextField
                  fullWidth
                  style={{ marginBottom: 10 }}
                  label="Email"
                  autoComplete="current-email"
                  {...field}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  style={{ marginBottom: 10 }}
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  {...field}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            />
            <Button variant="contained" type="submit">
              Login
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </>
  );
};

export default Login;
