import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid2, InputAdornment, Paper, TextField } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import GMRouteMap from "../../shared/GMRouteMap"
import useGetApi from "../../hooks/useGetApi"
import GMAddress from "../../shared/GMAddress"

const JobCustomer = (props: any) => {
    const { formik } = props

    const { data: customers } = useGetApi({ url: `/customers/GetRefList` });

    return <>
        <Paper style={{ padding: 10, marginTop: 10 }}>

            <Grid2 container spacing={2}>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="passengerName"
                        name="passengerName"
                        label="Passenger"
                        value={formik.values.passengerName ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <TextField
                        fullWidth
                        id="passengerPhone"
                        name="passengerPhone"
                        label="Phone"
                        value={formik.values.passengerPhone ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Autocomplete
                        fullWidth
                        id="customer"
                        options={customers ?? []}
                        getOptionLabel={(option: any) => option?.name ?? ''}
                        isOptionEqualToValue={(option, value) => option?.customerID === value?.customerID}
                        value={formik.values.customer ?? ''}
                        onChange={(_, newValue) => {
                            formik.setFieldValue('customer', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => <TextField {...params} name="customer" label="Customer" />}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <TextField
                        fullWidth
                        id="notes"
                        name="notes"
                        label="Notes"
                        multiline
                        value={formik.values.notes ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={formik.values.isTour}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Tour" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.hasTrailer}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Trailer" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isReversePickup"
                                    checked={formik.values.isReversePickup}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Reverse Pickup" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isWedding"
                                    checked={formik.values.isWedding}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Wedding" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isInterstate"
                                    checked={formik.values.isInterstate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Interstate" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isCountryside"
                                    checked={formik.values.isCountryside}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Countryside" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isTaxiReformLevyExempted"
                                    checked={formik.values.isTaxiReformLevyExempted}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            }
                            label="Exempted" />
                    </FormGroup>
                </Grid2>
            </Grid2>

        </Paper>


    </>
}

export default JobCustomer