import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import Jobs from "./jobs/Jobs";
import Login from "./Login";
import NoMatch from "./NoMatch";

const Home = () => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated()) return <Login />;

  return (
    <Switch>
      <Route path="/jobs">
        <Jobs />
      </Route>
      <Route exact path="/">
        <Redirect to="/jobs" />
      </Route>
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Home;
