import { LocationOn } from "@mui/icons-material";
import { Autocomplete, Box, debounce, Grid2, TextField, Typography } from "@mui/material";
import parse from 'autosuggest-highlight/parse';
import { useEffect, useMemo, useState } from "react";

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

const autocompleteService = { current: null };

const GMAddress = (props: any) => {
    const { name, label, formik } = props

    const [value, setValue] = useState<any>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<readonly any[]>([]);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                400,
            ),
        [],
    );

    useEffect(() => {
        const val = formik.values[name]
        if (!val) return
        setInputValue(val)
    }, [formik.values[name]])

    useEffect(() => {
        const description = typeof value === 'string' ? value : value?.description
        formik.setFieldValue(name, description ?? '')
    }, [value])

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly any[] = [inputValue];

                if (value) {
                    newOptions = [...newOptions, value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);

                setValue(newOptions[0])
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return <Autocomplete
        fullWidth
        getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value ?? ''}
        noOptionsText="No locations"
        onChange={(event: any, newValue: any) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField {...params} label={label} fullWidth />
        )}
        renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            const matches =
                option?.structured_formatting?.main_text_matched_substrings || [];

            const parts = parse(
                option?.structured_formatting?.main_text || option,
                matches.map((match: any) => [match.offset, match.offset + match.length]),
            );
            return (
                <li key={key} {...optionProps}>
                    <Grid2 container sx={{ alignItems: 'center' }}>
                        <Grid2 sx={{ display: 'flex', width: 44 }}>
                            <LocationOn sx={{ color: 'text.secondary' }} />
                        </Grid2>
                        <Grid2 sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                            {parts.map((part: any, index: number) => (
                                <Box
                                    key={index}
                                    component="span"
                                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                >
                                    {part.text}
                                </Box>
                            ))}
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {option?.structured_formatting?.secondary_text}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </li>
            );
        }}
    />
}

export default GMAddress