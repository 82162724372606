import { createContext } from "react";

export interface UserInfo {
  email: string;
  roles: string[];
  token: string;
  expireOn: Date;
}

export interface AuthData {
  user?: UserInfo;
  setUser: (user: any) => void;
}

export const AuthContext = createContext<AuthData>({
  setUser: () => {},
});
