import { Add, NavigateBefore, NavigateNext, Refresh } from "@mui/icons-material";
import { Button, Grid2, IconButton, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import React from "react";

const JobsHeader = (props: any) => {
  const { onNew, data, filter, setFilter, onRefresh } = props;

  return (
    <Grid2 container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: 20, marginBottom: 10 }} >

      <Grid2 size={12} style={{ textAlign: "center" }}>
        <TextField
          label="Jobs"
          value={data.length}
          disabled
          sx={{ width: 75 }}
        />


        <IconButton onClick={() => setFilter({ ...filter, fromDate: addDays(filter.fromDate, -1) })}>
          <NavigateBefore />
        </IconButton>
        <DateTimePicker
          name="From"
          label="From"
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          value={dayjs(filter.fromDate)}
          onChange={(newValue: any) => setFilter({ ...filter, fromDate: newValue })}
        />
        <DateTimePicker
          name="To"
          label="To"
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          value={dayjs(filter.toDate)}
          onChange={(newValue: any) => setFilter({ ...filter, toDate: newValue })}
        />
        <IconButton onClick={() => setFilter({ ...filter, toDate: addDays(filter.toDate, 1) })}>
          <NavigateNext />
        </IconButton>

        <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<Add />} onClick={() => onNew()}>
          Add Job
        </Button>
        <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<Refresh />} onClick={() => onRefresh()}>
          Refresh
        </Button >
      </Grid2>
    </Grid2 >
  );
};

export default JobsHeader;
